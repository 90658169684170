<template>
  <!-- 解决方案 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">深入洞察行业需求</div>
            <div class="backgroundImg2">为你提供专业的行业解决方案</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 行业痛点 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业痛点</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div
        class="industry"
        style="display: flex; justify-content: space-between;"
      >
        <div class="industry1">
          <div class="industry1Img1 industry1_1_1"></div>
          <div class="industry1_1">企业服务</div>
          <div class="industry1_2">
            物业管理行业自身发展的水平距离广大居民、业主的要去还存在一定的差距，诸如收费不规范、服务不及时、维修技术不过关，特别是服务理念、服务意识方面的滞后，在一些物业服务企业身上还时有发生，这在一定程度上损害了业主利益，损害了行业形象。
          </div>
        </div>
        <div class="industry1">
          <div class="industry1Img2 industry1_1_1"></div>
          <div class="industry1_1">坐席考核无依据</div>
          <div class="industry1_2">
            无法对销售人员或是客服人员的服务质量、数据进行把控，大批量的客户咨询和客户需求无法记录，核对数据资料需要相关部门开会统计，考核工作繁琐、效率低下。
          </div>
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">解决方案</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="industry">
        <div class="programme1">
          <div class="programme1-2">
            <div>建立物业服务呼叫中心系统纳入客户服务质量提升计划中的一项重要工作，很多物业最终选定了性价比颇高、技术能力较强的四川企讯通通讯科技有限公司作为</div>
            <div>此项目的合作商。针对物业客户的个性化需求，我们反复与其确认进行二次开发，并在客户指定的时间内交出了一份满意的答卷，获得了物业公司的肯定。</div>
            <div style="position: relative;top:30px;">物业呼叫中心设计的业务流程图如下：</div>
          </div>
          <div class="flex jc">
            <div class="programme-01"></div>
          </div>
        </div>
        <div class="programme1">
          <div class="programme1-2">
            <div>比如，物业呼叫中心系统已在成都总部以及东莞、惠州、武汉等分公司获得了应用，构建了物业跨区域、实时、集中式的客户服务管理体系，对与业主密</div>
            <div>切相关的费用查询、报修服务、投诉建议、咨询等实现了规范化、标准化的管理，对服务品质的提升起到质的跨越。我们认为，在服务至上的今天，呼叫中心</div>
            <div>必定在物业行业得到越来越多的普及。</div>
          </div>
        </div>
      </div>
      <div style="width: 1200px; padding-bottom: 82px; margin: 0 auto">
        <div class="back" @click="$router.go(-1)">返回</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style src="@/style/RealEstateIndustry.css" scoped></style>
<style lang="scss" scoped></style>
